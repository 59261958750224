.carousel {
    width: 100%;
    height: 510px;
    position: relative;
  }
  
  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
  }
  
  .firstImage {
    z-index: 1;
  }
  
  .firstImage[data-animate='left'] {
    transform: translateX(150%);
    transition: transform 2s ease-in-out;
  }
  
  .firstImage[data-animate='right'] {
    transform: translateX(-150%);
    transition: transform 2s ease-in-out;
  }
  
  .btn {
    position: absolute;
    border: 0;
    border-radius: 5px;
    bottom: 40px;
    z-index: 2;
  }
  
  .btn:hover {
    background-color: brown;
    cursor: pointer;
  }
  
  .prevBtn {
    left: 64px;
  }
  
  .nextBtn {
    right: 64px;
  }